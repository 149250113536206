import React, { useEffect } from 'react'
import AtlantaTemplate from '../../../templates/AtlantaTemplate'

const AtlantaLocation = () => {

    return (
        <AtlantaTemplate>
            <div class="salesforce-footer"></div>
			<div class="heroContent">
				<div class="heroSky">
					<div class="hottieCenter">
						<img class="herohottie" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/hero/2023_BC_Regional-Landing-Page_Hootie.gif" alt="background" />
					</div>
					<img class="heroballon" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/hero/2023_BC_Regional-Landing-Page_USA-Balloon.gif" alt="background" />
				</div>
			</div>

			<section class="container-content">
				<h1 class="h1Internal">Location</h1>
				<h3>Set your GPS.</h3>

				<div class="hotel-holder"></div>
				<p>Trailblazer Bootcamp will take place at the <a href="http://www.marriott.com/en-us/hotels/atlbc-atlanta-marriott-buckhead-hotel-and-conference-center/overview/" target="_blank">Atlanta Marriott Buckhead Hotel & Convention Center</a>.</p>

			</section>
		
			<section class="container-content">
				<div class="center">
					<img class="logo logo-loc logoInternal" src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/home/2023-Bootcamp-Seal_Atlanta_Color.png" />
				</div>
			</section>


			<div class="characters schedule-bg">
				<img src="https://developer.salesforce.com/resources2/bootcamps2022/Atlanta/Images/location/Location.png" />
			</div>
        </AtlantaTemplate>
    )
}

export default AtlantaLocation
